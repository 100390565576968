.App {
  text-align: center;
}

a {
  color: white !important;
  text-decoration: none;
}

.App-logo {
  height: 111px;
  width: 445px;
  pointer-events: none;
}

.App-header {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./cinema.jpg') no-repeat center center/cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p {
  font-size: large;
  width: 50%;
  text-align: center;
  letter-spacing: 0.07rem;
  line-height: 1.7rem;
  margin: 1vh;
}

.reseaux {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  #reseaux-footer {
    font-size: 40px;
    margin: 2vh;
    color: white !important;
  }

}

.container {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.password-field {
  width: 250px;
  padding: 0.55rem;
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  border-radius: 4px;
  outline: none;
}

.password-field:focus {
  border-color: #00bfff;
  box-shadow: 0 0 5px #00bfff;
}

@media (max-width: 900px) {
  p {
    width: 80%;
    margin: 1vh
  }

  h1 {
    margin: 1vh;
  }
}

@media (max-width: 600px) {
  p {
    font-size: 1rem;
  }

  h1 {
    margin: 1vh;
  }

  #reseaux-footer {
    color: white !important;
  }

  a {
    color: white !important;
    text-decoration: none;
  }

  .App-logo {
    height: 66px;
    width: 267px;
  }

}

@media (max-width: 480px) {
  p {
    font-size: 0.9rem;
    width: 90%;
    line-height: 1.1rem;
    letter-spacing: 0rem;
  }

  h1 {
    font-size: 1.7em;
  }

  .App-logo {
    margin-bottom: 10vh;
  }
}